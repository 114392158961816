"use client";
import fetchJson from "@/lib/fetchJson";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { capitalize } from "@/lib/util";
import { Button, Flex, IconButton, Text, TextField } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import * as Dialog from "@/components/CustomComponents/Dialog"
import ClearableSelect from "@/components/ui/ClearableSelect";
import Search from "@/components/CustomComponents/NewSearch";
import { useAllCampaigns } from "@/services/campaign";

export default function NewAddBudget({
  mutate,
  campaignId,
  defaultCreator,
  defaultPrice,
  defaultCount,
}: any) {
  const [count, setCount] = useState(defaultCount || "");
  const [price, setPrice] = useState(defaultPrice || "");
  const { data } = useSWR<Creator[]>("/creator", fetchJson);
  const [selectedCreator, setSelectedCreator] = useState(defaultCreator || null);
  const [selectedCampaignId, setSelectedCampaignId] = useState(campaignId || null)
  const [creatorData, setCreatorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { data: campaigns } = useAllCampaigns()

  const handleSetCreator = async (newCreatorData) => {
    const creator = await fetchJson(`/creator/${newCreatorData.value}`, {
      method: "GET"
    })
    console.log("creatorData", creator)
    setCreatorData(creator?.creator)
  }

  useEffect(() => {
    console.log("defaultCreator", defaultCreator)
    if (defaultCreator && defaultCreator?.id) {
      handleSetCreator({value: defaultCreator.id})
    }
  }, [defaultCreator])

  const add = async (close: any) => {
    setLoading(true)
    if (!count || !price || !selectedCreator)
      return alert("Please fill all fields");
    console.log("ADDING", count, price, campaignId)
    await fetchJson(`/creator/budget`, {
      method: "POST",
      body: JSON.stringify({
        count: parseInt(count),
        price: price,
        campaign: campaignId,
      }),
    });
    
    setLoading(false)
    setCount("");
    setPrice("");
    setSelectedCreator(undefined);
    close();
    mutate();
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Button>
          <IconPlus className="icon-accent"/>
          Add Budget
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
      <Dialog.Content style={{maxWidth: "450px"}}>
        <Dialog.Title>Add Budget</Dialog.Title>
        {!campaignId && <Search
          def={selectedCampaignId || null}
          data={(campaigns || []).map((i) => ({ value: i.id, label: i.name }))}
          onChange={(e) => {
            setSelectedCampaignId(e.value)
          }}
          prompt="Enter campaign name"
          placeholder="Search campaigns..."
          width={"100%"}
          />}
        <Flex direction="column" gap="3">
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Creator
          </Text>
          {defaultCreator ? <Text size="2">{creatorData?.name}</Text>: <Search
          def={selectedCreator || null}
          data={(data || []).map((i) => ({ value: i.id, label: i.name }))}
          onChange={(e) => {
            setSelectedCreator(e)
            handleSetCreator(e)}}
          prompt="Enter creator name"
          placeholder="Search creator..."
          width={"100%"}
          />}
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Platform
          </Text>
          <ClearableSelect
          placeholder="Select platform..."
          options={(creatorData?.prices || []).map((i: any) => ({
            value: i.id,
            label: `@${i.username} (${capitalize(i.platform)})`,
          }))}
          value={price}
          onValueChange={setPrice}/>
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Number of Posts
          </Text>
          <TextField.Root
              value={count}
              onChange={(e) => setCount(e.target.value)}
              variant="soft"
            />
        </label>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button 
              variant="soft" 
              color="gray"
              onClick={() => {
                setCount("");
                setPrice("");
                setSelectedCreator(undefined);
              }}>
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button 
            onClick={() => add(close)}
            loading={loading}
            >
              Save
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
