import React, { useState, useTransition , useMemo, memo } from "react";
import * as Ariakit from "@ariakit/react";
import { matchSorter } from "match-sorter";
import styles from "./NewMultiSelect.module.css"
interface Props {
  options: string[]; 
  selected: any;
  setSelected: Function;
  title: string;
  style?: any;
  allowAdd?: boolean;
  onAdd?: any;
  transparent?: boolean;
}

const NewMultiSelect = ({
  options,
  selected,
  setSelected,
  title,
  style,
  allowAdd,
  onAdd,
  transparent
}: Props) => {
    const [isPending, startTransition] = useTransition();
    const [searchValue, setSearchValue] = useState("");
    
    const matches = useMemo(() => {
      const selectedSet = new Set(selected)
      const result = selected.concat(matchSorter([...options], searchValue).filter(i => !selectedSet.has(i)))
      console.log("result", result)
      return result
    }, [searchValue, options]);
    console.log("searchValue", searchValue)
    const hasExact = useMemo(() => {
      if (!allowAdd) {
        return false
      }
      const matched = matches.find(match => match === searchValue)
      console.log("matched", matched)
      return matched !== null && matched !== undefined;
    }, [matches, allowAdd])
    console.log("hasExact", hasExact, allowAdd)
  return (
    <Ariakit.ComboboxProvider
    selectedValue={selected}
    setSelectedValue={(value) => setSelected(value)}
    setValue={(value) => {
      startTransition(() => {
        setSearchValue(value);
      });
    }}
  >
    <div className={`${styles["combobox-wrapper"]}`} style={style}>
    <Ariakit.Combobox
      placeholder={title}
      className={!transparent ? styles.combobox : `${styles.combobox} ${styles.transparent}`}
      
      />
    <Ariakit.ComboboxDisclosure className={`${styles.button} ${styles.secondary} ${styles.disclosure}`} />
    </div>
    <Ariakit.ComboboxPopover
      sameWidth
      gutter={8}
      className={styles.popover}
      aria-busy={isPending}
      key={matches.length}
    >
      {matches.map((value) => (
        <Ariakit.ComboboxItem
          key={value}
          value={value}
          focusOnHover
          className={styles["combobox-item"]}
        >
          <Ariakit.ComboboxItemCheck />
          {value}
        </Ariakit.ComboboxItem>
      ))}
      {searchValue.length > 0 && allowAdd && !hasExact && <div style={{cursor: "pointer"}} onClick={() => onAdd(searchValue)}>+ Add "{searchValue}"</div> }
      {matches.length === 0 && <div className={styles["no-results"]}>No results found</div>}
    </Ariakit.ComboboxPopover>
  </Ariakit.ComboboxProvider>
  );
};
export default (NewMultiSelect);
