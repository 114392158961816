import { Button, Flex, Table } from "@radix-ui/themes";
import { IconArrowsSort, IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import Image from "next/image";
import { useEffect, useState } from "react";

interface PostsListViewProps {
    fullPosts: Post[]
}
const PostsListView = ({ fullPosts }: PostsListViewProps) => {
    const [page, setPage] = useState(0)
    const PAGE_SIZE = 10
    const [sortKey, setSortKey] = useState("Post Date")
    const headers = ["Post", "Author", "Post Date", "Likes", "Shares", "Views", "Comments"]
    const [posts, setPosts] = useState([])
    const headerToKey = {
        "Post Date": "postedAt",
        "Author": "username",
        "Views": "views",
        "Likes": "likes",
        "Shares": "shares",
        "Comments": "comments"
    }
    

    useEffect(() => {
        setPosts([...fullPosts].sort((a: any, b: any) => {
            const key = headerToKey[sortKey]
            if (key === "postedAt") {
                return new Date(b[key]).getTime() - new Date(a[key]).getTime()
            }
            return b[key] - a[key]
        }))
    }, [sortKey])
    
    return (
        <Flex direction="column" gap="4">
        <Table.Root size="1">
            <Table.Header>
                <Table.Row>
                    {headers.map((item: any) => (
                        <Table.ColumnHeaderCell style={{cursor: headerToKey[item] === undefined ? "auto" : "pointer"}} 
                        onClick={() => {
                            if (headerToKey[item] === undefined) {
                                return
                            }
                            setSortKey(item)
                            }}  
                        key={item}>
                        <Flex direction="row" align="center" gap="1">
                            {item}
                            {headerToKey[item] !== undefined &&
                            <IconArrowsSort size="20px" className="icon"/>}
                        </Flex>
                            </Table.ColumnHeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {posts.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((post) => (
                    <Table.Row align="center" key={post.id}>
                        <Table.Cell width="10%">
                            <div className="small-thumbnail"  style={{position: "relative"}}>
                        {post.thumbnailSmall ? <Image 
                        fill
                        className="small-thumbnail"
                        src={`https://wvemedia.com/image-upload/${post.thumbnailSmall}`}
                        alt=""
                        /> : <Image 
                        fill
                        className="small-thumbnail"
                        src="/PostImage.png"
                        alt=""
                        />}
                        </div>
                        </Table.Cell>
                        <Table.Cell width="20%">@{post.username}</Table.Cell>
                        <Table.Cell width="15%">{new Date(post.postedAt)?.toLocaleDateString()}</Table.Cell>
                        <Table.Cell width="15%">{(post.shares && post.views && !post.likes )? 'Hidden' : post.likes ? post.likes.toLocaleString() : ''}</Table.Cell>
                        <Table.Cell width="15%">{post.shares?.toLocaleString()}</Table.Cell>
                        <Table.Cell width="15%">{post.views?.toLocaleString()}</Table.Cell>
                        <Table.Cell width="15%">{post.comments?.toLocaleString()}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table.Root>
        <Flex direction="row" gap="3" justify="center">
            <Button variant="ghost" disabled={page === 0} onClick={() => setPage((prev: any) => prev - 1) }><IconChevronLeft className="icon-accent"/>Previous</Button>
            <Button variant="ghost" disabled={page >= (posts?.length / PAGE_SIZE - 1)} onClick={() => setPage((prev: any) => prev + 1) }>Next<IconChevronRight  className="icon-accent"/></Button>
        </Flex>
        </Flex>
    )
}

export default PostsListView