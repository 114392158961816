import Link from "next/link";
import { KeyedMutator } from "swr";
import { useState } from "react";
import { deleteBudget } from "@/services/creator";
import NewEditBudget from "@/components/Campaign/modals/NewEditBudget";
import { Avatar, Flex, Heading, IconButton, Table, Text } from "@radix-ui/themes";
import { findPrice } from "@/lib/util";
import { IconBrandInstagram, IconBrandTiktok, IconBrandX, IconBrandYoutube, IconTrash } from "@tabler/icons-react";

var groupBy = function(xs: any[], key: Function) {
  return xs.reduce(function(rv, x) {
    (rv[key(x)] = rv[key(x)] || []).push(x);
    return rv;
  }, {});
};



export const Budget = ({ budget, posts }: any) => {
  const getIcon = (budget) => {
    if (budget.creatorPrice.platform === "tiktok") {
      return <IconBrandTiktok className="icon"/>
    }
    if (budget.creatorPrice.platform === "instagram") {
      return <IconBrandInstagram className="icon"/>
    }
    if (budget.creatorPrice.platform === "twitter") {
      return <IconBrandX className="icon"/>
    }
    if (budget.creatorPrice.platform === "youtube") {
      return <IconBrandYoutube className="icon"/>
    }
  }

  const postCount = (posts: Post[], user: string, platform: string) => {
    return (
      posts?.filter((p) => p.username === user && p.type === platform).length || 0
    );
  };
  const count = postCount(
    posts,
    budget?.creatorPrice.username,
    budget?.creatorPrice.platform,
  );
  const budgetBg = () => {
    if (!budget) {
      return
    }
    if (count <= budget.budget / 3) {
      return "#E56262"
    }
    else if (count < budget.budget){
      return "#E5B062"
    }
    else if (count >= budget.budget){
      return "#449E42"
    }
  }
  const [bg, setBg] = useState(budgetBg)

  const link = (platform: CreatorPrice) => {
    if (platform.platform === "instagram") {
      return `https://instagram.com/${platform.username}`;
    } else if (platform.platform === "tiktok") {
      return `https://tiktok.com/@${platform.username}`;
    } else if (platform.platform === "youTube") {
      return `https://youtube.com/${platform.username}`;
    } else if (platform.platform === "twitter") {
      return `https://twitter.com/${platform.username}`;
    }
    return "";
  };
  
  if (!budget) return <div></div>;
  
  
  return (
    <Flex gap="3" direction="row" align="center">
      <Flex className="progress" style={{
            height: "15px",
            width: "45vw",
            borderRadius: "100px", 
          }}>
            {count === 0 && <Flex width="100%" direction="row" justify="end" px="2">
            <Text size="1">{count} of {budget.budget}</Text>
          </Flex>}
        <Flex
          style={{
            width: `${Math.min(Math.round((count * 100) / budget.budget), 100)}%`,
            height: "15px",
            backgroundColor: bg,
            borderRadius: "100px", 
          }}
        >
          {count !== 0 && <Flex width="100%" direction="row" justify="end" px="2">
            <Text size="1">{count} of {budget.budget}</Text>
          </Flex>}
        </Flex>
      </Flex>
      <Link href={link(budget.creatorPrice)} target="_blank">
      <Flex direction="row" gap="2" align="center" justify="start">
        {getIcon(budget)}
        <Text size="1">@{budget.creatorPrice.username}</Text>
      </Flex>
      </Link>
    </Flex>
  );
};

const find = (budgets: any[], platform: string) => {
  return budgets.filter((b) => b.creatorPrice.platform === platform);
};

interface CreatorsProps {
  campaign: Campaign;
  mutate: KeyedMutator<Campaign>;
  style?: string;
  platforms?: string[];
}

const CreatorsTab = ({ platforms, campaign, mutate, style }: CreatorsProps) => {
  const [typeFilter, setTypeFilter] = useState({} as any);
  const lowerCasePlatforms = platforms.map(p => p.toLowerCase())

  const budgets = typeFilter?.value
    ? campaign.budgets.filter(
      (b) => b.creatorPrice.creator.creatorType?.id === typeFilter.value,
    )
    : campaign.budgets;
  const grouped = Object.values(
    groupBy(budgets, (b: any) => b.creatorPrice.creatorId),
  );

  const prices = campaign.budgets.map((b) => b.creatorPrice);

  const totalBookedPosts = () => {
    if (!lowerCasePlatforms) {
      return budgets.map((item: any) => item.budget).reduce((a: number, b: number) => a + b, 0)} 
      else {
    return budgets
      .filter((item: any) => lowerCasePlatforms.includes(item.creatorPrice.platform.toLowerCase()))
      .map((item: any) => item.budget)
      .reduce((a: number, b: number) => a + b, 0)}}

  const totalBookedPrice = () =>
    budgets
      .filter((item: any) => lowerCasePlatforms.includes(item.creatorPrice.platform.toLowerCase()))
      .map((item: any) => item.budget * item.creatorPrice.price)
      .reduce((a: number, b: number) => a + b, 0);

  const totalRealizedPosts = () => {
    if (!lowerCasePlatforms) {
      return campaign.post.filter(
        (item: any) =>
          budgets.some(
            (b: any) =>
              b.creatorPrice.platform === item.type &&
              b.creatorPrice.username === item.username,
          ),
      ).length
    } else {
      return campaign.post.filter(
        (item: any) =>
        lowerCasePlatforms.includes(item.type.toLowerCase()) &&
          budgets.some(
            (b: any) =>
              b.creatorPrice.platform === item.type &&
              b.creatorPrice.username === item.username,
          ),
      ).length}}

  const totalRealizedPrice = () =>
    campaign.post
      .filter((item: any) => lowerCasePlatforms.includes(item.type.toLowerCase()))
      .map((item: any) => {
        const b = budgets.find(
          (b: any) =>
            b.creatorPrice.platform === item.type &&
            b.creatorPrice.username === item.username,
        );
        return b?.creatorPrice.price || 0;
      })
      .reduce((a: number, b: any) => a + b, 0);

  const typesInit = campaign.budgets
    .map((i) => i.creatorPrice.creator.creatorType)
    .filter((i) => i)
    .map((i) => ({ value: i.id, text: i.tag }));
  let types = [] as any;
  for (let i = 0; i < typesInit.length; i++) {
    if (!types.find((x: any) => x.value === typesInit[i].value)) {
      types.push(typesInit[i]);
    }
  }
  let budget = 0;
  if (lowerCasePlatforms.includes("instagram")) {
    budget += campaign.instagramBudget
  }
  if (lowerCasePlatforms.includes("tiktok")) {
    budget += campaign.tiktokBudget
  }
   
  const totalSpent = campaign?.post
    .map((p) => findPrice(campaign, p))
    .filter((i) => lowerCasePlatforms.includes(i?.platform.toLowerCase()))
    .map((i) => i?.price || 0)
    .reduce((a, b) => a + b, 0); 
  
    const headers = [
      "Creator",
      "Posting Progress",
      "Actions",
    ]
    const removeBudget = async (id: string) => {
      if (confirm("Are you sure you want to delete this budget?")) {
        await deleteBudget(id);
        mutate();
      }
    };
  console.log("grouped", grouped)

  return (
    <Flex direction="column" gap="5" pt="4">
      <Flex className="box" direction="column" p="5" gap="5" justify="between">
        <Flex direction="row" gap="2" justify="between">
            <Heading size="4">Budget Analytics</Heading>
        </Flex>
        {style === "mobile" ? 
        <Flex direction="column" gap="3" justify="start">
          <Flex direction="column" gap="1">
            <Text size="2">Selected Platform</Text>
            <Heading size="7">{platforms.length === 4 ? "All Platforms" : platforms.join(", ")}</Heading>
          </Flex>
          <Flex direction="row" gap="6">
            <Flex direction="column" gap="1">
              <Text size="2">Total Posts</Text>
              <Heading size="7">{totalRealizedPosts()} / {totalBookedPosts()}</Heading>
            </Flex>
            <Flex direction="column" gap="1">
              <Text size="2">Total Budget</Text>
              <Heading size="7">${totalSpent} / ${budget}</Heading>
            </Flex>
        </Flex>
      </Flex>:
        <Flex direction="row" gap="9" justify="start">
          <Flex direction="column" gap="1">
            <Text size="2">Selected Platform</Text>
            <Heading size="8">{platforms.length === 4 ? "All Platforms" : platforms.join(", ")}</Heading>
          </Flex>
          <Flex direction="column" gap="1">
            <Text size="2">Total Posts</Text>
            <Heading size="8">{totalRealizedPosts()} / {totalBookedPosts()}</Heading>
          </Flex>
          <Flex direction="column" gap="1">
            <Text size="2">Total Budget</Text>
            <Heading size="8">${totalSpent} / ${budget}</Heading>
          </Flex>
        </Flex>}
      </Flex>

      <Flex className="box" direction="column" gap="5" p="5">
        <Heading size="4">Creators Posting Progress</Heading>
        <Table.Root>
          <Table.Header>
              <Table.Row>
                  {headers.map((item: any) => (
                      <Table.ColumnHeaderCell key={item}>{item}</Table.ColumnHeaderCell>
                  ))}
              </Table.Row>
          </Table.Header>
          <Table.Body>
          {grouped.map((b: any) => {
            const filteredBudgets = b.filter(budget => lowerCasePlatforms.includes(budget.creatorPrice.platform.toLowerCase()))
            if (filteredBudgets.length === 0) {
              return null;
            }
            return (
            <Table.Row key={b[0].id}>
              <Table.Cell>
                <Flex direction="row" align="center" gap="2">
                  <Avatar  
                    src={`/image-upload/${b[0].creatorPrice.avatar}`}
                    fallback={Array.from(b[0].creatorPrice.creator.name)[0] as string}
                    radius="full"/> 
                  <Link href={`/creators/${b[0].creatorPrice.creatorId}`}>
                    {b[0].creatorPrice.creator.name}
                  </Link>
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex direction="column" gap="5" justify="center" height="100%">
                  {filteredBudgets.map((budget: any) => (
                    <Budget
                      key={budget.id}
                      budget={budget}
                      posts={campaign.post}
                      campaignId={campaign.id}
                      mutate={mutate}
                      multiple={b.length > 1}
                    />
                  ))}
                  </Flex>
              </Table.Cell>
              <Table.Cell>
                <Flex direction="column" gap="2">
                  {filteredBudgets.map((budget: any) => (
                  <Flex direction="row" gap="2" align="center" key={budget}>
                    <NewEditBudget
                        mutate={mutate}
                        budget={budget}
                        creator={budget.creatorPrice.creator}
                        price={budget.creatorPrice}
                    />
                    <IconButton variant="outline" onClick={() => removeBudget(budget.id)}>
                      <IconTrash className="icon-accent" />
                    </IconButton>
                  </Flex>))}
                </Flex>
              </Table.Cell>
            </Table.Row>
          )
          })}
          </Table.Body>
        </Table.Root>
      </Flex>
    </Flex>
  );
};

export default CreatorsTab;
