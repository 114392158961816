import fetchJson from "@/lib/fetchJson";
import useSWR from "swr";

export const useCampaign = (id: string) =>
  useSWR<Campaign>(`/campaign/${id}`, fetchJson);

export const useSharedCampaign = (id: string) =>
  useSWR<Campaign>(`/campaign/share/${id}`, fetchJson);

export const useMyCampaigns = (status: string = "active") =>
  useSWR<Campaign[]>(`/campaign/?status=${status}`, fetchJson);

export const useAllCampaigns = () =>
  useSWR<Campaign[]>(`/campaign`, fetchJson);

export const updateTracking = async (campaign: Campaign | undefined) => {
  if (!campaign) return;
  await fetchJson(`/campaign/${campaign.id}/tracking`, {
    method: "POST",
    body: JSON.stringify({ shouldTrack: !campaign.shouldTrack }),
  });
};

export const archiveCampaign = async (campaign: Campaign | undefined) => {
  if (!campaign) return;

  await fetchJson(`/campaign/${campaign?.id}/status`, {
    method: "POST",
    body: JSON.stringify({ status: "archived" }),
  });
};

interface UpdateCampaignData {
  name: string;
  instagramBudget: number;
  tiktokBudget: number;
  dailyStreams: number;
  artist: number | null;
  song: number | null;
  streamTarget: number | null;
  admins: string[];
}

export const updateCampaign = async (id: number, data: UpdateCampaignData) => {
  await fetchJson(`/campaign/${id}`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const mergeCampaigns = async (mergeFrom: number, mergeTo: number) => {
  await fetchJson(`/campaign/merge/${mergeFrom}`, {
    method: "POST",
    body: JSON.stringify({
      id: mergeTo,
    }),
  });
};

export const deleteHashtag = async (id: number) =>
  fetchJson(`/campaign/hashtag/${id}`, {
    method: "DELETE",
  });

export const deleteMention = async (id: number) =>
  fetchJson(`/campaign/mention/${id}`, {
    method: "DELETE",
  });

export const updateStatus = async (id: number, status: string) =>
  fetchJson(`/campaign/${id}/status`, {
    method: "POST",
    body: JSON.stringify({ status }),
  });

export const transferCampaign = async (
  campaignId: number,
  newAgencyId: string,
) =>
  fetchJson(`/campaign/transfer`, {
    method: "POST",
    body: JSON.stringify({
      campaignId,
      newAgencyId,
    }),
  });
