import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider,
} from "@ariakit/react";
import * as RadixSelect from "@radix-ui/react-select";
import { startTransition, useMemo, useRef, useState } from "react";
import {matchSorter} from 'match-sorter'
import { MagnifyingGlassIcon, ChevronDownIcon, CheckIcon} from '@radix-ui/react-icons';
import { Flex } from "@radix-ui/themes";

interface SearchProps {
  onChange: Function;
  data: any; // expects data as [{label: "", value: ""}]
  placeholder?: string;
  prompt?: string;
  def?: any;
  width?: any
}

export default function Search({ onChange, data, placeholder, prompt, def, width }: SearchProps) { 
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(def || null);
  const [searchValue, setSearchValue] = useState("");
  const options = data as {label: string, value: string}[]
  const inputRef = useRef()


  const matches = useMemo(() => {
    if (!searchValue) return options.filter(match => match.value !== '');
    const keys = ["label", "value"];
    const matches = matchSorter(options, searchValue, { keys });
    return matches.filter(match => match.value !== '');
  }, [searchValue, value]);

  const placeholderLabel = placeholder ? placeholder : null
  const selectedLabel = value ? value.label : placeholderLabel
  return (
    <RadixSelect.Root
      value={value}
      onValueChange={(e) => {
        if (data) {
          const selected = data.find((name) => name.value === e);
          setValue(e)
          onChange(selected)
        }
      }}
      open={open}
      onOpenChange={setOpen}
      {...(width ? {style: {width: width}} : {})}
    >
      <ComboboxProvider
        open={open}
        setOpen={setOpen}
        resetValueOnHide
        includesBaseElement={false}
        

        {...(width ? {style: {width: width}} : {})}
        setValue={(value) => {
          startTransition(() => {
            setSearchValue(value);
          });
        }}
      >
        <RadixSelect.Trigger ref={inputRef}  className="select" {...(width ? {style: {width: width}} : {})}>
          <RadixSelect.Value placeholder={<Flex direction="row" gap="1" align="center" {...(width ? {style: {width: width}} : {})}>
              <MagnifyingGlassIcon />
            Search...</Flex>}>
              {selectedLabel}
            </RadixSelect.Value>
          <RadixSelect.Icon className="select-icon">
            <ChevronDownIcon />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Content
          role="dialog"
          aria-label="Campaigns"
          position="popper"
          className="popover"
          sideOffset={4}
          style={{...(width && inputRef && inputRef.current ? {width: (inputRef?.current as any).clientWidth || '100%'} : {})}}
        >
          <div className="combobox-wrapper" {...(width ? {style: {width: width}} : {})}>
            <div className="combobox-icon">
              <MagnifyingGlassIcon />
            </div>
            <Combobox
              autoSelect
              
              placeholder={prompt ? prompt :"Enter campaign name"}
              className="combobox"
              {...(width ? {style: {width: width}} : {})}
              // Ariakit's Combobox manually triggers a blur event on virtually
              // blurred items, making them work as if they had actual DOM
              // focus. These blur events might happen after the corresponding
              // focus events in the capture phase, leading Radix Select to
              // close the popover. This happens because Radix Select relies on
              // the order of these captured events to discern if the focus was
              // outside the element. Since we don't have access to the
              // onInteractOutside prop in the Radix SelectContent component to
              // stop this behavior, we can turn off Ariakit's behavior here.
              onBlurCapture={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          </div>
          <ComboboxList className="listbox" key={`${JSON.stringify(matches)}`} {...(width ? {style: {width: width}} : {})}>
            {matches.map(({ label, value }, index) => (
              <RadixSelect.Item
                key={`${value}-${index}`}
                value={value}
                asChild
                className="item"
                style={{cursor: 'pointer', ...(width ? {width: width} : {})}}
              >
                <ComboboxItem style={{cursor: 'pointer', ...(width ? {width: width} : {})}}>
                  <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                  <RadixSelect.ItemIndicator className="item-indicator">
                    <CheckIcon />
                  </RadixSelect.ItemIndicator>
                </ComboboxItem>
              </RadixSelect.Item>
            ))}
          </ComboboxList>
        </RadixSelect.Content>
      </ComboboxProvider>
    </RadixSelect.Root>
  );
}